import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { getIsLoggedIn } from './services/authService'
import Cookies from 'universal-cookie';
import './GlobalVariable/globalVariable'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./pages/Login'))
const isAuthenticated = getIsLoggedIn();
const cookies = new Cookies();

class App extends Component {
  render(): JSX.Element {
    return (
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            {(isAuthenticated || cookies.get('googletoken') != null)
              ? <Route path="*" element={<DefaultLayout />}></Route>
              : <Route path="*" element={<Login />}></Route>
            }
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
